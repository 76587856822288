<template>
  <div class="background py-16">
    <ForgotPassword />
  </div>
</template>

<script>
import { mapState } from "vuex";

import ForgotPassword from "@/components/elements/auth/forgot-password/ForgotPassword";

export default {
  name: "DocumentManagementForgotPassword",

  components: {
    ForgotPassword,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (this.auth.loggedIn) {
      this.$router.push({ name: "DashboardInbox" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
