<template>
  <div class="forgot-password">
    <v-container class="content pb-5">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="5" class="hidden-sm-and-down">
            <v-img
              alt="img"
              class="img"
              src="@/assets/img/auth/img-1.jpg"
              max-width="600"
            />
          </v-col>

          <v-col cols="12" offset-md="1" md="6" align-self="center">
            <v-card color="pa-5 pa-md-8" rounded="xl">
              <h3 class="heading-h3">Forgot Password</h3>
              <v-row class="mt-2">
                <v-col cols="12" class="d-flex flex-column">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    outlined
                    required
                  ></v-text-field>

                  <router-link :to="{ name: 'Login' }">
                    Remember password login</router-link
                  >

                  <v-btn @click="validate()" class="primary mt-4" rounded>
                    Send
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",

  data: () => ({
    overlay: false,

    // redirect
    redirect: "/dashboard/inbox",

    // email
    email: "",
    emailRules: [
      (v) => !!v || "Required",
      (v) => v.length <= 100 || "Max 100 characters",
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<script>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Invalid e-mail.";
      },
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],
  }),

  computed: {},

  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.forgotPassword();
      }
    },

    async forgotPassword() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "forgot-password",
        {
          email: this.email,
        },
        "Please check your email for password reset instructions"
      );

      if (res.status == 200) {
        this.email = "";

        this.$refs.form.resetValidation();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  // min-height: 100vh;
  .content {
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }
}
</style>
